<template>
  <div class="row">
    <div class="col-md-12">
      <template>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12" lg="11">
                <v-row>
                  <v-col cols="12" lg="2">
                    <v-text-field
                      v-model="tableFilters.code"
                      append-icon="filter_alt"
                      :label="$t('PAGES.CITIZENS.LIST.FILTERS.CODE')"
                      single-line
                      hide-details
                      clearable
                      @keyup.enter.native="searchWithFilter()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-text-field
                      v-model="tableFilters.name"
                      append-icon="filter_alt"
                      :label="$t('PAGES.CITIZENS.LIST.FILTERS.NAME')"
                      single-line
                      hide-details
                      clearable
                      @keyup.enter.native="searchWithFilter()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-text-field
                      v-model="tableFilters.surname"
                      append-icon="filter_alt"
                      :label="$t('PAGES.CITIZENS.LIST.FILTERS.SURNAME')"
                      single-line
                      hide-details
                      clearable
                      @keyup.enter.native="searchWithFilter()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-text-field
                      v-model="tableFilters.location_residence_search"
                      append-icon="place"
                      :label="
                        $t('PAGES.CITIZENS.LIST.FILTERS.LOCATION_RESIDENCE')
                      "
                      single-line
                      hide-details
                      clearable
                      @keyup.enter.native="searchWithFilter()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-text-field
                      v-model="tableFilters.location_domicile_search"
                      append-icon="place"
                      :label="
                        $t('PAGES.CITIZENS.LIST.FILTERS.LOCATION_DOMICILE')
                      "
                      single-line
                      hide-details
                      clearable
                      @keyup.enter.native="searchWithFilter()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="2">
                    {{ tableFilters.fiscalCode }}
                    <v-text-field
                      v-model="tableFilters.fiscalCode"
                      append-icon="place"
                      :label="$t('PAGES.CITIZENS.LIST.FILTERS.FISCAL_CODE')"
                      single-line
                      hide-details
                      clearable
                      @keyup.enter.native="searchWithFilter()"
                      g
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="1">
                <v-btn
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  class="ma-q white--text mt-3"
                  @click="searchWithFilter()"
                >
                  {{ $t("PAGES.CITIZENS.LIST.FILTERS.FILTER") }}
                  <v-icon right dark>mdi-filter</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            fixed-header
            class="elevation-1"
            :headers="tableData.headers"
            :pageCount="totalPages"
            :page="1"
            :server-items-length="totalResults"
            :items="dataTableList"
            :items-per-page="itemsPerPage"
            :options.sync="options"
            :loading="citizenLoading"
            :footer-props="footerProps"
            item-key="ID"
          >
            <template v-slot:item.status="{ item }">
              <span>
                <v-icon color="green" v-if="item.status == true"
                  >mdi-check-circle</v-icon
                >
                <v-icon color="red" v-else>mdi-minus-circle</v-icon>
              </span>
            </template>
            <template v-slot:item.code="{ item }">
              {{ item.code }}
            </template>
            <template v-slot:item.date_of_birth="{ item }">
              <span v-if="item.date_of_birth != null">{{
                new Date(item.date_of_birth).toLocaleString([], {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              }}</span>
            </template>
            <template v-slot:item.created_at="{ item }">
              <span v-if="item.created_at != null"
                >{{
                  new Date(item.created_at).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })
                }}
              </span>
              <span v-else>---</span>
            </template>
            <template v-slot:item.subscription_date="{ item }">
              <span v-if="item.subscription_date != null">{{
                new Date(item.subscription_date).toLocaleDateString()
              }}</span>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text icon color="green">
                <v-icon @click="$router.push('/citizens/reservations/' + item.id)"
                  >mdi-folder-account</v-icon
                >
              </v-btn>
              <v-btn text icon color="blue">
                <v-icon @click="manageItem(item)">mdi-pencil</v-icon>
              </v-btn>
              <v-btn text icon color="red">
                <v-icon @click="deleteItem(item)">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline"
                      >{{ $t("COMMON.DIALOG.DELETE_CONFIRM") }}
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >{{ $t("COMMON.DIALOG.ABORT") }}
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >{{ $t("COMMON.DIALOG.OK") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="500px" :persistent="true" />
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_CITIZENS_LIST,
  DELETE_CITIZEN,
} from "@/core/services/store/citizens.module";
import { mapGetters } from "vuex";
import { FETCH_FULL_STATES_LIST } from "@/core/services/store/states.module";

export default {
  data() {
    return {
      filter_list: {
        code: { q: "code", t: "_like" },
        name: { q: "name", t: "_like" },
        surname: { q: "surname", t: "_like" },
        city_of_residence: { q: "city_of_residence.denomination", t: "_like" },
        city_of_domicile: { q: "city_of_domicile.denomination", t: "_like" },
        states: { q: "fiscal_province.id", t: "_eq" },
        status: { q: "status", t: "_eq" },
        fiscalCode: { q: "fiscalCode", t: "_like" },
        subscription_from: { q: "created_at_date", t: "_gt" },
        subscription_to: { q: "created_at_date", t: "_lt" },
      },
      dialog_edit: false,
      filters: {
        query: [],
        filters: {},
        sort: [
          {
            direction: "DESC",
            field: "id",
          },
        ],
        page: 1,
        limit: 10,
      },
      single_item_loading: false,
      dialog: false,
      dialogDelete: false,
      page: 1,
      totalItems: 0,
      numberOfPages: 1,
      options: {},
      itemsPerPage: 10,
      optionsLength: 6,
      pagination: {
        page: 1,
        rowsPerPage: 20,
        itemsPerPage: 20,
      },
      footerProps: {
        showFirstLastPage: false,
        "items-per-page-options": [10, 20, 50, 100],
        "items-per-page-text": this.$t("COMMON.TABLES.PAGINATION"),
      },
      totalPage: Number,
      totalResults: Number,
      // users_list_data: [],
      tableFilters: {},
      tableData: {
        headers: [
          {
            text: this.$t("COMMON.TABLES.COLUMN.CODE"),
            align: "left",
            sortable: true,
            value: "code",
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.NAME"),
            value: "name",
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.SURNAME"),
            value: "surname",
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.FISCAL_CODE"),
            value: "fiscal_code",
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.DATE_OF_BIRTH"),
            value: "date_of_birth",
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.CREATED_AT"),
            value: "created_at",
          },

          {
            text: this.$t("COMMON.TABLES.COLUMN.OPERATION"),
            value: "action",
            sortable: false,
          },
        ],
      },
      editedItem: {},
      defaultItem: {},
      item_to_edit: {},
      empty_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1,
      },
      status: [
        {
          value: 1,
          name: this.$t("PAGES.CITIZENS.LIST.FILTERS.STATUS_1"),
        },
        {
          value: 2,
          name: this.$t("PAGES.CITIZENS.LIST.FILTERS.STATUS_2"),
        },
        {
          value: 3,
          name: this.$t("PAGES.CITIZENS.LIST.FILTERS.STATUS_3"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["citizensList", "fullStateList", "citizenLoading"]),
    dataTableList() {
      return this.citizensList.data;
    },
    subscription_from_formatted() {
      return this.tableFilters.subscription_from
        ? moment(String(this.tableFilters.subscription_from)).format(
            "DD/MM/YYYY"
          )
        : "";
    },
    subscription_to_formatted() {
      return this.tableFilters.subscription_to
        ? moment(String(this.tableFilters.subscription_to)).format("DD/MM/YYYY")
        : "";
    },
  },
  components: {
  },
  methods: {
    convertDataToPicker(date) {
      if (date != undefined) {
        return new Date(date).getDate();
      }
    },
    onClearDateClicked(type) {
      type == "from"
        ? (this.tableFilters.subscription_from = "")
        : (this.tableFilters.subscription_to = "");
    },
    readDataFromAPI(reset) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let pageNumber;
      if (reset == true) {
        pageNumber = 1;
        this.filters.limit = itemsPerPage;
      } else {
        pageNumber = page == 0 ? 1 : page;
        this.filters.limit = itemsPerPage;
      }
      this.filters.page = pageNumber;
      if (sortBy != undefined && sortBy.length > 0) {
        this.filters.sort = [
          {
            direction: sortDesc != undefined && sortDesc[0] ? "DESC" : "ASC",
            field: sortBy[0],
          },
        ];
      }
      this.$store.dispatch(FETCH_CITIZENS_LIST, this.filters);
    },
    refreshFilters() {},
    searchWithFilter() {
      let self = this;
      var tmp;
      Object.keys(this.filter_list).forEach(function (value) {
        if (
          self.tableFilters[value] == undefined ||
          self.tableFilters[value] == null ||
          self.tableFilters[value] === ""
          // self.tableFilters[value]
        ) {
          self.$delete(self.filters.filters, self.filter_list[value].q);
        } else {
          if (value == "subscription_from" || value == "subscription_to") {
            switch (value) {
              case "subscription_from":
                tmp = {};
                tmp.subscription_date = {
                  _gt: self.tableFilters["subscription_from"],
                };
                self.filters.filters["_from"] = tmp;
                if (
                  self.tableFilters["subscription_to"] == undefined ||
                  self.tableFilters["subscription_to"] == null ||
                  self.tableFilters["subscription_to"] == ""
                ) {
                  tmp = {};
                  tmp.subscription_date = {
                    _lt: new Date().toISOString().slice(0, 10),
                  };
                  self.filters.filters["_to"] = tmp;
                }
                break;
              case "subscription_to":
                tmp = {};
                tmp.subscription_date = {
                  _lt: self.tableFilters["subscription_to"],
                };
                self.filters.filters["_to"] = tmp;
                if (
                  self.tableFilters["subscription_from"] == undefined ||
                  self.tableFilters["subscription_from"] == null ||
                  self.tableFilters["subscription_from"] == ""
                ) {
                  tmp = {};
                  tmp.subscription_date = {
                    _gt: "1900-01-01",
                  };
                  self.filters.filters["_from"] = tmp;
                }
                break;
            }
            self.filters.filters["_cmb_and"] = "_from,_to";
          } else {
            switch (self.filter_list[value].t) {
              case "_like":
                self.filters.filters[self.filter_list[value].q] = {
                  _like: "%" + self.tableFilters[value] + "%",
                };
                break;
              case "_eq":
                self.filters.filters[self.filter_list[value].q] = {
                  _eq: self.tableFilters[value],
                };
                break;
              case "_gt":
                self.filters.filters[self.filter_list[value].q] = {
                  _gt: self.tableFilters[value],
                };
                break;
              case "_lt":
                self.filters.filters[self.filter_list[value].q] = {
                  _lt: self.tableFilters[value],
                };
                break;
            }
          }
        }
      });

      this.readDataFromAPI(true);
    },
    zeroFill(number, width = 8) {
      width -= number.toString().length;
      if (width > 0) {
        return (
          new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number
        );
      }
      return number + ""; // always return a string
    },
    checkTextFiltert(text) {
      if (text != undefined) {
        return "%" + text + "%";
      }
      return "%";
    },
    updateDialogLoading(status) {
      this.single_item_loading = status;
    },
    manageItem(item) {
      this.$router.push("/citizens/edit/" + item.id);
    },
    deleteItem(item) {
      this.editedIndex = this.dataTableList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.dataTableList.splice(this.editedIndex, 1);
      this.$store.dispatch(DELETE_CITIZEN, this.editedItem);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PAGES.CITIZENS.AREA") },
      { title: this.$t("PAGES.CITIZENS.LIST.TITLE") },
    ]);
    this.$store.dispatch(FETCH_CITIZENS_LIST, this.filters);
    this.$store.dispatch(FETCH_FULL_STATES_LIST, this.empty_filters);
  },
  watch: {
    citizensList() {
      this.totalPage = this.citizensList.meta != undefined
          ? this.citizensList.meta.pagination.total_pages
          : 0;
      this.totalResults = this.citizensList.meta != undefined
          ? this.citizensList.meta.pagination.total
          : 0;
      this.page = this.citizensList.meta != undefined
          ? this.citizensList.meta.pagination.total_pages
          : 0;
    },
    dialog(val) {
      val || this.close();
    },
    dialog_edit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.readDataFromAPI();
      },
    },
  },
};
</script>
